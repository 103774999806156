import React from 'react';
import { render } from "react-dom"
//import './index.css';
import App from './App';

//import 'bootstrap/dist/css/bootstrap.min.css';
//import "./main.css"
import { BrowserRouter } from "react-router-dom"
//import Sidebar from './paginas/Sidebar';
//import Header from './paginas/Header';
render(
  <BrowserRouter>
  <App/>
  </BrowserRouter>,
    document.querySelector("#root")
);

