import React, { useRef, useEffect } from "react";
import io from "socket.io-client";

const socket = io("http://localhost:3636");

const Assistir = () => {
  const videoRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const sourceBufferRef = useRef(null);

  useEffect(() => {
    // Cria um MediaSource para o vídeo
    mediaSourceRef.current = new MediaSource();
    videoRef.current.src = URL.createObjectURL(mediaSourceRef.current);

    // Quando o MediaSource estiver aberto, adiciona um SourceBuffer
    mediaSourceRef.current.addEventListener("sourceopen", () => {
      sourceBufferRef.current = mediaSourceRef.current.addSourceBuffer("video/webm; codecs=\"vp8\"");
    });

    // Recebe dados de vídeo do servidor
    socket.on("video-data", (data) => {
      if (sourceBufferRef.current) {
        sourceBufferRef.current.appendBuffer(data); // Adiciona os dados de vídeo ao buffer
      }
    });

    return () => {
      socket.disconnect(); // Desconecta o socket ao desmontar o componente
    };
  }, []);

  return (
    <div>
      <h1>Assistir Tela em Tempo Real</h1>
      <video ref={videoRef} autoPlay controls style={{ width: "500px", height: "300px" }}></video>
    </div>
  );
};

export default Assistir;
