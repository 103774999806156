import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons';
class Planos extends Component {
    componentDidMount(){
 document.title = "Planos e preços"
    }
  render() {
    return (<div style={{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        height:'100vh'
        }}>
 <div class="container py-5">
    <div class="text-center mb-5">
      <h1 class="display-4">Escolha o Plano Perfeito</h1>
      <p class="lead">Potencialize suas URLs com nossos planos flexíveis. Desde opções gratuitas até funcionalidades premium para profissionais.</p>
    </div>

    <div class="row text-center">
   
      <div class="col-lg-4 mb-4">
        <div class="card h-100 shadow-sm border-primary">
          <div class="card-header bg-primary text-white py-4">
            <h4 class="my-0">Gratuito</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">R$0 <small>/mês</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Até 100 URLs por mês</li>
              <li>Expiração após 30 dias</li>
              <li>Estatísticas básicas</li>
              <li>Exibição de anúncios</li>
            </ul>
            <button type="button" class="btn btn-outline-primary w-100">Em breve</button>
          </div>
        </div>
      </div>

   
      <div class="col-lg-4 mb-4">
        <div class="card h-100 shadow-sm border-success">
          <div class="card-header bg-success text-white py-4">
            <h4 class="my-0">Básico</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">R$1<small>/mês</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>Até 1.000 URLs por mês</li>
              <li>URLs sem expiração</li>
              <li>Estatísticas detalhadas</li>
              <li>URLs personalizadas</li>
              <li>Sem anúncios</li>
            </ul>
            <button type="button" class="btn btn-success w-100">Em breve</button>
          </div>
        </div>
      </div>


      <div class="col-lg-4 mb-4">
        <div class="card h-100 shadow-sm border-danger">
          <div class="card-header bg-danger text-white py-4">
            <h4 class="my-0">Pro</h4>
          </div>
          <div class="card-body">
            <h1 class="card-title pricing-card-title">R$2 <small>/mês</small></h1>
            <ul class="list-unstyled mt-3 mb-4">
              <li>URLs ilimitadas</li>
              <li>Estatísticas avançadas</li>
              <li>URLs de marca (seu domínio)</li>
              <li>A/B Testing para redirecionamentos</li>
              <li>Redirecionamento dinâmico</li>
              <li>Suporte prioritário</li>
            </ul>
            <button type="button" class="btn btn-danger w-100">Em breve</button>
          </div>
        </div>
      </div>
    </div>
  </div>
    </div>

       
    );
  }
}

export default Planos;