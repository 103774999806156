import React, { useRef, useState } from "react";
import io from "socket.io-client";

const socket = io("http://localhost:3636"); // Conexão com o servidor Socket.IO

const ScreenShare = () => {
  const [isRecording, setIsRecording] = useState(false);
  const videoRef = useRef(null);

  const startRecording = async () => {
    const stream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: false,
    });
    videoRef.current.srcObject = stream;
    setIsRecording(true);

    const mediaRecorder = new MediaRecorder(stream);
    
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        socket.emit("video-data", event.data); // Envia os dados de vídeo para o servidor
      }
    };

    mediaRecorder.start(100); // Envia dados a cada 1 segundo
  };

  const stopRecording = () => {
    setIsRecording(false);
    videoRef.current.srcObject.getTracks().forEach(track => track.stop());
  };

  return (
    <div>
      <h1>Gravar Tela</h1>
      <video ref={videoRef} autoPlay style={{ width: "500px", height: "300px" }}></video>
      <br />
      {!isRecording ? (
        <button onClick={startRecording}>Gravar Tela</button>
      ) : (
        <button onClick={stopRecording}>Parar Gravação</button>
      )}
    </div>
  );
};

export default ScreenShare;
