import React from 'react';
import PropTypes from 'prop-types';

// Definindo a animação de carregamento com CSS-in-JS
const skeletonStyle = {
  height: '100px', // Valor padrão
  width: '100px',  // Valor padrão
  backgroundColor: '#e0e0e0',
  borderRadius: '4px',
  display: 'inline-block',
  position: 'relative',
  overflow: 'hidden',
};

const loadingAnimation = `
  @keyframes pulse {
    0% {
      background-color: #e0e0e0;
    }
    50% {
      background-color: #c0c0c0;
    }
    100% {
      background-color: #e0e0e0;
    }
  }
`;

const Skeleton = ({ height, width }) => {
  const style = {
    ...skeletonStyle,
    height: height || '100px',
    width: width || '100px',
    animation: 'pulse 1.5s infinite ease-in-out',
  };

  return (
    <>
      <style>
        {loadingAnimation}
      </style>
      <div style={style}></div>
    </>
  );
};

// Definindo as propriedades esperadas
Skeleton.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Skeleton;
