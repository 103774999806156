import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faDollar,
    faBoxOpen,
    faChartBar,
    faStore,
    faCheckCircle,
    faCalendar,
    faUsers,
    faListAlt
  } from '@fortawesome/free-solid-svg-icons';
import { faMoneyCheck } from '@fortawesome/free-solid-svg-icons/faMoneyCheck';

class Funcoes extends Component {
 componentDidMount(){
    document.title = "Funções | MaxPdv"
 }

  render() {
    return (
      <>
 <section id="about-us" style={{marginTop:'80px'}} className="work section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-title">
                  <h2>Diversas funcionalidades para gerenciar seu negócio</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faDollar} /></span>
                  </div>
                  <h3>Controle de Vendas</h3>
                  <p>Controle suas vendas de uma forma simples e fácil, sem complicações!</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faBoxOpen} /></span>
                  </div>
                  <h3>Estoque</h3>
                  <p>Seu estoque sempre atualizado, contabilizando entradas e saídas de mercadorias.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faChartBar} /></span>
                  </div>
                  <h3>Relatórios</h3>
                  <p>Crie relatórios de contas a pagar e a receber, vendas, fiados, comissão de vendedores, etc.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faStore} /></span>
                  </div>
                  <h3>Catálogo online</h3>
                  <p>Com o catálogo você pode criar sua loja virtual e receber pedidos de qualquer lugar</p>
                </div>
              </div>
              
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12">
              <br/>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faCalendar} /></span>
                  </div>
                  <h3>Agenda</h3>
                  <p>Nosso PDV oferece um sistema de agenda integrado, permitindo que você gerencie compromissos e horários com facilidade e eficiência.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faUsers} /></span>
                  </div>
                  <h3>Funcionários</h3>
                  <p>Gerencie sua equipe com eficiência. Registre informações, atribua permissões e otimize horários com facilidade.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faListAlt} /></span>
                  </div>
                  <h3>Pedente</h3>
                  <p>Gerencie facilmente os pedidos pendentes no seu sistema PDV. Mantenha o controle de todas as transações em andamento, agilizando o atendimento e garantindo a satisfação dos clientes.</p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="single-work last">
                  <div className="serial">
                    <span><FontAwesomeIcon icon={faDollar} /></span>
                  </div>
                  <h3>Despesas</h3>
                  <p>Gerencie todas as despesas de forma eficiente no seu sistema PDV. Registre e categorize gastos facilmente para manter o controle financeiro do seu negócio de maneira organizada e precisa.</p>
                </div>
              </div>
              
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Funcoes;